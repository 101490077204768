import React from 'react';

import { Layout } from '../../components/layout';
import SEO from '../../components/seo';

export default function ProductDesigner(): JSX.Element {
  return (
    <Layout>
      <SEO title="Product Designer" />
      <div className="container">
        <nav className="breadcrumb">
          <a href="/#jobs">Jobs</a>
          <span>Product Designer</span>
        </nav>
        <h2>Product Designer (Fulltime / Remote)</h2>
        <h4>About us</h4>
        <p>
          We are a product development studio based in Berlin working on
          products for startups and help established companies innovating on
          their products and services. We work in multi-disciplinary teams
          applying agile workflows which helps us solve business problems for
          our clients by building the <em>right</em> product/service.
        </p>
        <p>
          We're looking for a thoughtful and passionate Product Designer to join
          our team. You will become a central part of the product team, working
          directly with product managers and clients to solve big problems and
          alongside our engineers to build performant, user-centric products for
          the web and mobile. Product designers at nerdgeschoss are expected to
          be experienced in multiple design disciplines and fluent in the
          language of how digital products work today.
        </p>
        <h4>What you will do</h4>
        <p></p>
        <ul>
          <li>
            design digital interfaces for mobile and web from scratch or
            sometimes iterate on existing products
          </li>
          <li>
            work with engineers, product managers and other designers as well as
            clients
          </li>
          <li>
            visualise concepts that we as a team come up with and present it to
            stakeholders
          </li>
          <li>
            facilitate or help facilitate workshops involving clients and co
            workers
          </li>
          <li>participate in design research</li>
          <li>
            work on internal products as well as branding and communication
            strategies for our company
          </li>
        </ul>
        <p />
        <h4>You have to be…</h4>
        <p></p>
        <ul>
          <li>an excellent communicator</li>
          <li>
            comfortable facilitating workshops and generally good in presenting
            ideas
          </li>
          <li>fluent in either Sketch or Figma</li>
          <li>
            excellent in visual design basics like typography, color theory,
            information hierarchy…
          </li>
          <li>
            able to understand business problems and also challenge them if it
            seems appropriate
          </li>
          <li>curious and eager to learn new things</li>
        </ul>
        <p />
        <h4>The must-have list for your application</h4>
        <p></p>
        <ul>
          <li>
            3+ years of experience as a designer working on digital products
          </li>
          <li>a portfolio that reflects your UX and visual design skills</li>
        </ul>
        <p />
        <p>
          <a className="link" href="mailto:hiring@nerdgeschoss.de">
            Sounds interesting? Let's talk
          </a>
        </p>
      </div>
    </Layout>
  );
}
